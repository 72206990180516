import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-plugin-transition-link";
// import { differenceInDays, formatDistance, format } from "date-fns";

import { getBlogUrl } from "../../lib/helpers";
// import { getBlogUrl, toPlainText } from "../../lib/helpers";
// import PortableText from "./portableText";

function BlogPostPreview(props) {
  // const truncate = (str) => {
  //   return str.length > 200 ? str.substring(0, 200) + "..." : str;
  // };

  return (
    <Link
      to={getBlogUrl(props.slug.current)}
      className="group block font-normal text-typography-body no-underline hover:text-typography-body"
    >
      {props.featuredImage && props.featuredImage.asset && (
        <div className="mb-5 max-h-64 overflow-hidden rounded-xl">
          <GatsbyImage
            image={props.featuredImage.asset.gatsbyImageData}
            loading="lazy"
            className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
          />
        </div>
      )}
      <div className="mb-1 flex flex-wrap items-center font-body text-sm font-medium uppercase tracking-wide text-primary-900/40 md:flex-nowrap">
        {props.categories && (
          <ul className="flex flex-wrap md:flex-nowrap">
            {props.categories.map((category, i) => (
              <li
                className="mb-2 text-sm font-bold uppercase text-primary-500"
                key={i}
              >
                {i > 0 ? <>, {category.title}</> : category.title}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="heading-four mb-0">{props.title}</div>
    </Link>
  );
}

export default BlogPostPreview;
